import axios from "axios";

// const controller = new AbortController();

// export const cancelRequests = () => {
// 	controller.abort();
// };

export const getProductNamesByBarcode = async (data) => {
	try {
		let response = await axios.post(
			"https://nomera.sigma-studio.pp.ua/barcode/get_names.php",
			data
		);
		return response.data;
	} catch (error) {
		//console.log(error);
	}
};

export const getCachedProducts = async (data) => {
	try {
		let response = await axios.post(
			"https://scanme-admin.sigma-studio.pp.ua/api/getCachedProducts.php",
			data
		);
		return response.data;
	} catch (error) {
		//console.log(error);
	}
};

export const getProductsFromSerp = async (data) => {
	try {
		let response = await axios.post(
			"https://nomera.sigma-studio.pp.ua/barcode/get_products_from_shop.php",
			data
		);
		return response.data;
	} catch (error) {
		//console.log(error);
	}
};

export const getProductsFromEbay = async (data) => {
	try {
		let response = await axios.post(
			"https://nomera.sigma-studio.pp.ua/barcode/get_products_from_ebay.php",
			data
		);
		return response.data;
	} catch (error) {
		//console.log(error);
	}
};

export const getProductsFromAmazon = async (data) => {
	try {
		let response = await axios.post(
			"https://realtika.com/amazon/amazon.php",
			data
		);
		return response.data;
	} catch (error) {
		//console.log(error);
	}
};
