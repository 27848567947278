<template>
	<div class="open_scanner" @click="openScanner()" v-show="showButton"></div>
</template>

<script>
export default {
	emits: ["openScanner"],

	data() {
		return {
			showButton: false
		};
	},

	methods: {
		openScanner() {
			this.$emit("openScanner");
		},

		show() {
			this.showButton = true;
		},

		hide() {
			this.showButton = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.open_scanner {
	position: fixed;
	bottom: 2em;
	left: 50%;
	transform: translateX(-50%);
	width: 3.063em;
	height: 3.063em;
	background-color: #2d69d0;
	border-radius: 50%;
	z-index: 97;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 52%;
		background-image: url("@/assets/images/svg/barcode_button.svg");
	}
}
</style>
