import * as store from "../store";

export const addToHistory = (product, search) => {
	const currentDate = new Date();
	const hours = currentDate.getHours();
	const minutes = currentDate.getMinutes();
	const day = currentDate.getDate();
	const month = currentDate.getMonth();
	const year = currentDate.getFullYear();
	const time = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
	let historyProduct = {
		thumbnail: product.thumbnail,
		search,
		title: product.title,
		date:
			("0" + day).slice(-2) + "." + ("0" + (month + 1)).slice(-2) + "." + year,
		time
	};
	store.store.commit("ADD_TO_HISTORY", historyProduct);
};
