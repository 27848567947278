<template>
	<transition name="show">
		<div
			class="modal_scanner"
			:class="[{ pwa: pwa }, { pwa_camera_inited: pwaCameraInited }]"
			v-show="open"
		>
			<div class="start_camera" v-if="pwa"></div>
			<div class="start_button" v-if="pwa">
				<p>
					<span>Start</span>
					<span>Camera</span>
				</p>
			</div>
			<div class="scanner_container barcode_scanner">
				<div class="search input_search">
					<div class="lens"></div>
					<input type="text" placeholder="Search..." v-model="search" />
					<div class="success" @click="searchBy(search)"></div>
					<text-tooltip ref="searchTooltip" text="Enter code or name" />
				</div>
				<div
					id="scanner"
					:class="{ camera_inited: cameraInited }"
					class="scanner"
				></div>
				<div
					class="frame"
					:class="{ scanning: scanning }"
					@click="startScanner()"
				>
					<searching-animation ref="searchingAnimation" />
					<div class="border">
						<svg
							version="1.1"
							id="Рамка_1_"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							width="713px"
							height="712px"
							viewBox="0 0 713 712"
							enable-background="new 0 0 713 712"
							xml:space="preserve"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								fill="#34A751"
								d="M103.043,0.186l-0.982-0.004H46.194
	C20.9,0.184,0.321,20.764,0.319,46.058l-0.04,56.083c0,2.026,1.648,3.674,3.674,3.674s3.674-1.647,3.674-3.673L7.586,46.057
	c0.001-10.31,4.018-20.005,11.309-27.3c7.294-7.29,16.99-11.307,27.302-11.309l56.85,0.085c2.026,0,3.674-1.648,3.674-3.674
	S105.073,0.186,103.043,0.186z"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								fill="#34A751"
								d="M609.951,7.533l56.854-0.085
	c10.31,0.002,20.005,4.019,27.301,11.311c7.29,7.293,11.306,16.988,11.308,27.299l-0.041,56.083c0,2.026,1.648,3.674,3.674,3.674
	s3.674-1.647,3.674-3.673l-0.04-56.085C712.679,20.763,692.1,0.184,666.806,0.182h-55.017v0.004h-1.836
	c-2.025,0-3.674,1.648-3.674,3.674S607.927,7.533,609.951,7.533z"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								fill="#34A751"
								d="M103.05,704.467l-56.856,0.085
	c-10.309-0.002-20.004-4.019-27.3-11.31c-7.29-7.294-11.306-16.989-11.308-27.301l0.041-56.082c0-2.025-1.648-3.674-3.674-3.674
	s-3.674,1.647-3.674,3.672l0.04,56.086c0.001,25.294,20.581,45.873,45.875,45.875h55.017v-0.004h1.836
	c2.026,0,3.674-1.648,3.674-3.674S105.073,704.467,103.05,704.467z"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								fill="#34A751"
								d="M709.047,606.186c-2.025,0-3.674,1.647-3.674,3.672l0.041,56.086
	c-0.002,10.31-4.018,20.005-11.308,27.299c-7.297,7.291-16.992,11.308-27.303,11.31l-56.851-0.085c-2.025,0-3.674,1.648-3.674,3.674
	s1.648,3.674,3.677,3.674l0.982,0.004h55.867c25.294-0.002,45.873-20.582,45.875-45.877l0.04-56.082
	C712.721,607.834,711.072,606.186,709.047,606.186z"
							/>
						</svg>
					</div>
				</div>
				<div class="fixed_buttons">
					<div
						class="fixed_button close_button"
						@click="closeScanner()"
						v-show="products.shop.length || products.advert.length"
					></div>
					<div class="fixed_button history" @click="openSearchHistory()">
						<div class="icon"></div>
						<span class="title">History</span>
						<span class="count">
							<vue3-autocounter
								ref="counter"
								:startAmount="0"
								:endAmount="searchHistory.list.length"
								:duration="1"
							/>
						</span>
					</div>
				</div>
			</div>
			<!-- <div class="scanner_container name_scanner" v-if="type == 'name'">
				<div class="fixed_search search">
					<input type="text" placeholder="Product name" v-model="productName" />
					<div class="success" @click="searchByName()"></div>
				</div>
			</div> -->
		</div>
	</transition>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { Html5Qrcode } from "html5-qrcode";
import {
	getProductNamesByBarcode,
	getCachedProducts,
	getProductsFromSerp,
	getProductsFromEbay
} from "@/api/getProducts.js";
import { addToHistory } from "@/plugins/historySearches.js";
import { prepareProducts } from "@/plugins/prepareProducts.js";
import TextTooltip from "@/components/Tooltips/TextTooltip.vue";
import SearchingAnimation from "@/components/Lottie/SearchingAnimation.vue";
import Vue3autocounter from "vue3-autocounter";
export default {
	components: {
		TextTooltip,
		SearchingAnimation,
		"vue3-autocounter": Vue3autocounter
	},

	data() {
		return {
			open: true,
			scanner: null,
			devices: [],
			searchStatus: true,
			cameraInited: false,
			scanning: false,
			products: {
				all: [],
				shop: [],
				advert: [],
				ebay: [],
				amazon: []
			},
			barcode: "",
			pwa: false,
			//showSearchBarcode: false,
			search: "",
			pwaCameraInited: false
		};
	},

	computed: {
		...mapState(["searchHistory"])
	},

	async mounted() {
		await this.getCameraPermission();
		await this.startScanner();
		if (
			window.matchMedia("(display-mode: standalone)").matches ||
			window.navigator.standalone ||
			document.referrer.includes("android-app://")
		) {
			this.pwa = true;
			setTimeout(() => {
				document.querySelector(".start_button").classList.add("show");
			}, 300);
			document.addEventListener("visibilitychange", () => {
				this.pwaCameraInited = false;
			});
		}
	},

	methods: {
		async openScanner() {
			this.open = true;
			this.searchStatus = true;
			this.barcode = "";
			this.search = "";
			setTimeout(() => {
				this.$refs.searchTooltip.show();
				this.$refs.counter.start();
			}, 300);
			//if (!this.pwa && startScanner) {
			await this.startScanner();
			//}
		},

		async closeScanner() {
			if (this.scanner) {
				await this.stopScanner();
			}
			if (document.querySelector("#scanner")) {
				document.querySelector("#scanner").classList.remove("shape_anim");
			}
			this.open = false;
			this.searchStatus = true;
			this.scanning = false;
			setTimeout(() => {
				this.$emit("showTooltip", "buttonTooltip");
			}, 100);
			if (this.$refs.searchingAnimation) {
				this.scanning = false;
				this.$refs.searchingAnimation.hideAnimation();
			}
		},

		async startScanner() {
			this.cameraInited = false;
			this.scanner = await new Html5Qrcode("scanner");
			this.scanner
				.start(
					this.devices[this.devices.length - 1].id,
					{
						fps: 10,
						qrbox: { width: 200, height: 200 }
					},
					(decodedText) => {
						this.onScanSuccess(decodedText);
					},
					async () => {}
				)
				.then(() => {
					setTimeout(() => {
						this.cameraInited = true;
						if (this.pwa) {
							this.pwaCameraInited = true;
						}
					}, 200);
				})
				.catch(async (error) => {
					console.log(error);
					await this.stopScanner();
					await this.startScanner();
				});
		},

		async stopScanner() {
			await this.scanner.stop();
			this.scanner = null;
			this.cameraInited = false;
		},

		async getCameraPermission() {
			let devices = await Html5Qrcode.getCameras();
			if (devices && devices.length) {
				this.devices = devices;
			}
		},

		async getNameByBarcode(barcode) {
			if (typeof barcode !== "undefined") {
				this.barcode = barcode;
			}

			if (!this.barcode || typeof this.barcode === "undefined") {
				alert("Wrong barcode");
				return;
			}
			if (this.$refs.searchingAnimation) {
				this.scanning = true;
				this.$refs.searchingAnimation.showAnimation();
			}

			if (document.querySelector("#scanner")) {
				document.querySelector("#scanner").classList.add("shape_anim");
			}
			let formData = new FormData();
			formData.append("barcode", this.barcode);

			let searches = await getProductNamesByBarcode(formData);
			if (searches.length) {
				this.getProducts(searches, "barcode", this.barcode);
			} else {
				this.$emit("updateProducts", this.products);
				this.closeScanner();
				this.$emit("showNoResult");
				setTimeout(() => {
					this.$emit("showTooltip", "buttonTooltip");
				}, 100);
				return;
			}
		},

		async searchBy(search) {
			if (!/^[0-9]+$/.test(search)) {
				this.search = search;
				await this.searchByName(search);
			} else {
				await this.getNameByBarcode(search);
			}
		},

		async searchByName(search) {
			let splittedSearch = search.split(" ");
			if (splittedSearch.length < 1) {
				alert("Ошибка в названии");
				return;
			}
			if (document.querySelector("#scanner")) {
				document.querySelector("#scanner").classList.add("shape_anim");
			}
			if (this.$refs.searchingAnimation) {
				this.scanning = true;
				this.$refs.searchingAnimation.showAnimation();
			}
			splittedSearch = splittedSearch.join(",");
			let searches = [splittedSearch];
			await this.getProducts(searches, "name", this.search);
		},

		async getProducts(searches, type, search) {
			this.clearProducts();
			window.scrollTo({ top: 0, behavior: "smooth" });
			let products = {
				all: [],
				shop: [],
				advert: [],
				ebay: [],
				amazon: []
			};
			await this.getCachedProducts(type);
			for (let i = 0; i < searches.length; i++) {
				let productsShop = await this.getProductsFromSerp(searches[i], type);
				for (let key in productsShop) {
					products[key] = productsShop[key];
				}
			}
			if (this.products.shop.length || this.products.advert.length) {
				let ebayProducts = await this.getProductsFromEbay(searches, type);
				products.ebay = products.ebay.concat(ebayProducts);

				let amazonProducts = await this.getProductsFromAmazon(searches, type);
				products.amazon = products.amazon.concat(amazonProducts);

				await this.cacheProducts(products, type, search);
			}
			if (!this.products.shop.length && !this.products.advert.length) {
				this.searchStatus = true;
				this.$emit("updateProducts", this.products);
				this.$emit("showNoResult");
				this.closeScanner();
				return false;
			}
			// let cachedProducts = await this.getCachedProducts(type);
			// for (let key in cachedProducts) {
			// 	if (!products[key]) {
			// 		products[key] = [];
			// 	}
			// 	products[key] = products[key].concat(
			// 		products[key],
			// 		cachedProducts[key]
			// 	);
			// }
			// for (let i = 0; i < searches.length; i++) {
			// 	let shopProducts = await this.getProductsFromSerp(searches[i], type);
			// 	for (let key in shopProducts) {
			// 		if (!products[key]) {
			// 			products[key] = [];
			// 		}
			// 		products[key] = products[key].concat(
			// 			products[key],
			// 			shopProducts[key]
			// 		);
			// 	}
			// }
			// if (products.advert || products.shop) {
			// 	products.ebay = products.ebay.concat(
			// 		await this.getProductsFromEbay(searches, type).products
			// 	);

			// 	products.amazon = await this.getProductsFromAmazon(searches, type)
			// 		.products;

			// 	console.log(products);
			// 	await this.cacheProducts(products, type, search);
			// }
		},

		async saveSearchRequest(searches) {
			let formData = new FormData();
			formData.append("barcode", this.barcode);
			formData.append("addedFrom", "site");
			formData.append("searches", JSON.stringify(searches));
			await axios.post(
				"https://scanme-admin.sigma-studio.pp.ua/api/saveSearchResult.php",
				formData
			);
		},

		async cacheProducts(products, type, search) {
			let formData = new FormData();
			formData.append("search", search);
			formData.append("products", JSON.stringify(products));
			await axios.post(
				"https://scanme-admin.sigma-studio.pp.ua/api/cacheProducts.php",
				formData
			);
		},

		async getCachedProducts(type) {
			let formData = new FormData();
			if (type === "name") {
				formData.append("search", this.search);
			}
			if (type === "barcode") {
				formData.append("search", this.barcode);
			}
			formData.append("type", type);

			let products = await getCachedProducts(formData);
			for (let key in products) {
				this.products[key] = this.products[key].concat(products[key]);
			}
			this.$emit("updateProducts", this.products);
			for (let key in this.products) {
				if (this.open && this.products[key].length) {
					this.closeScanner();
				}
				return products;
			}
		},

		async getProductsFromSerp(search, type) {
			// this.products.shop = [];
			// this.products.advert = [];
			let getFromAdmin = true;
			let formData = new FormData();
			formData.append("search", search);
			formData.append("barcode", this.barcode);
			formData.append("type", type);
			formData.append(
				"value",
				type == "barcode" ? this.barcode : type == "name" ? this.search : ""
			);
			formData.append("getFromAdmin", getFromAdmin);
			let response = await getProductsFromSerp(formData);
			getFromAdmin = false;
			if (response.value == this.search || response.value == this.barcode) {
				for (let key in response.products) {
					if (typeof response.products[key] !== "undefined") {
						this.products[key] = this.products[key].concat(
							response.products[key]
						);
						this.products[key] = prepareProducts(this.products[key]);
					}
				}
			}

			this.$emit("updateProducts", this.products);
			if (this.products.shop.length || this.products.advert.length) {
				if (type == "barcode") {
					addToHistory(this.products.shop[0], this.barcode);
				}
				if (type == "name") {
					addToHistory(this.products.shop[0], this.search);
				}
				this.closeScanner();
				return response.products;
			}
			// if (!this.products.shop.length) {
			// 	this.searchStatus = true;
			// 	this.$emit("updateProducts", this.products);
			// 	this.$emit("showNoResult");
			// 	this.closeScanner();
			// 	setTimeout(() => {
			// 		this.$emit("showTooltip", "buttonTooltip");
			// 	}, 100);
			// 	return false;
			// }
		},

		async getProductsFromAmazon(searches, type) {
			//this.products.amazon = [];
			let name;
			if (searches.length) {
				for (let i = 0; i < searches.length; i++) {
					if (searches[i]) {
						name = searches[i];
						break;
					}
				}
			}

			let formData = new FormData();
			formData.append("search", name);
			formData.append(
				"value",
				type == "barcode" ? this.barcode : type == "name" ? this.search : ""
			);
			formData.append("type", type);
			// let response = await axios.post(
			// 	"https://nomera.sigma-studio.pp.ua/barcode/get_products_from_amazon.php",
			// 	formData
			// );
			let response = await axios.post(
				"https://realtika.com/amazon/amazon.php",
				formData
			);
			if (response.value == this.search || response.value == this.barcode) {
				this.products.amazon = this.products.amazon.concat(
					response.data.products
				);
				this.products.amazon = prepareProducts(this.products.amazon);
			}
			this.$emit("updateProducts", this.products);
			return response.data;
			//}
		},

		async getProductsFromEbay(searches, type) {
			let name;
			if (searches.length) {
				for (let i = 0; i < searches.length; i++) {
					if (searches[i]) {
						name = searches[i];
						break;
					}
				}
			}
			let formData = new FormData();
			formData.append("search", name);
			formData.append("barcode", this.barcode);
			formData.append(
				"value",
				type == "barcode" ? this.barcode : type == "name" ? this.search : ""
			);
			formData.append("type", type);
			let response = await getProductsFromEbay(formData);
			if (
				response.products.length &&
				(response.value == this.search || response.value == this.barcode)
			) {
				this.products.ebay = this.products.ebay.concat(response.products);
				this.products.ebay = prepareProducts(this.products.ebay);
				this.products.ebay = this.products.ebay.slice(0, 25);
			}
			this.$emit("updateProducts", this.products);
			return response.products;
		},

		openSearchHistory() {
			if (this.searchHistory.list.length) {
				setTimeout(() => {
					this.closeScanner();
				}, 500);
				this.$emit("openSearchHistory");
			} else {
				alert("Search history is empty");
				return;
			}
		},

		onScanSuccess(decodedText) {
			this.cameraInited = false;
			this.barcode = decodedText;
			if (document.querySelector("#scanner")) {
				document.querySelector("#scanner").classList.add("shape_anim");
			}
			if (this.searchStatus) {
				this.searchStatus = false;
				this.getNameByBarcode();
			}
		},

		clearProducts() {
			this.products = {
				all: [],
				shop: [],
				advert: [],
				ebay: [],
				amazon: []
			};
		}

		// openSearchBarcode() {
		// 	this.showSearchBarcode = true;
		// },

		// closeSearchBarcode() {
		// 	this.showSearchBarcode = false;
		// },

		// clickOutsideInput() {
		// 	if (this.type == "barcode") {
		// 		if (
		// 			this.showSearchBarcode 	&&
		// 			!document
		// 				.querySelector(".fixed_button.search_button")
		// 				.contains(event.target) &&
		// 			!document.querySelector(".fixed_button.search").contains(event.target)
		// 		) {
		// 			//this.closeSearchBarcode();
		// 		}
		// 	}
		// 	if (this.type == "name") {
		// 		if (
		// 			this.open &&
		// 			!document
		// 				.querySelector(".name_scanner .fixed_search")
		// 				.contains(event.target)
		// 		) {
		// 			this.closeScanner();
		// 		}
		// 	}
		// }
	}
};
</script>

<style lang="scss" scoped>
.modal_scanner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: all 300ms ease-in-out;
	z-index: 98;
	&.pwa_camera_inited {
		.start_button {
			&.show {
				opacity: 0;
			}
		}
	}
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
		transform: translateY(20%);
		// &.type_name {
		// 	.name_scanner {
		// 		.fixed_search {
		// 			width: 3em;
		// 			.success {
		// 				opacity: 0;
		// 			}
		// 		}
		// 	}
		// }
		.frame {
			&:after {
				transform: translate(-50%, -60%);
				opacity: 0;
			}
		}
		.close_button {
			transform: translateX(-10%);
			opacity: 0;
		}
		.search_button {
			transform: translateX(10%);
			opacity: 0;
		}
	}
}
.scanner_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.start_camera {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background-color: black;
	//z-index: 2;
}
.start_button {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 1.5em;
	z-index: 1;
	opacity: 0;
	transition: $defaultTransition;
	&.show {
		opacity: 1;
	}
	p {
		overflow: hidden;
		height: 26px;
		text-align: center;
	}
	span {
		display: block;
		animation: change 7s 2s infinite;
	}
}
// .fixed_search {
// 	position: fixed;
// 	top: unset;
// 	right: 50%;
// 	width: 16em;
// 	height: 3em;
// 	background-color: white;
// 	border-radius: 50px;
// 	transform: translateX(50%);
// 	opacity: 1;
// 	transition: all 400ms 300ms ease-in-out;
// 	z-index: 2;
// 	input {
// 		padding: 0.5em 3em 0.5em 1em;
// 		text-align: inherit;
// 	}
// }
.barcode_scanner {
	&.camera_inited {
		&:before {
			opacity: 0;
		}
	}
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 101vw;
		height: 250vw;
		transform: translate(-50%, -50%);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		background-image: url("@/assets/images/svg/modal_scanner2.svg");
		opacity: 0.95;
		//opacity: 0.7;
		//filter: invert(1);
		z-index: 3;
		transition: all 300ms ease-in-out;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
		background-color: black;
		transition: all 300ms ease-in-out;
	}
}
.name_scanner {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.7;
		background-color: black;
		z-index: 5;
	}
}
.scanner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
	z-index: 2;
	&.camera_inited {
		&:after {
			opacity: 1;
		}
	}
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 9em;
		height: 9em;
		transform: translate(-50%, -50%);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		background-image: url("@/assets/images/svg/code.svg");
		opacity: 0;
		transition: all 400ms ease-in-out;
	}
	:deep(.qr-shaded-region) {
		display: none !important;
	}
	:deep(#qr-shaded-region) {
		display: none !important;
	}
	:deep(video) {
		object-fit: cover;
	}
}
.frame {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 21.625em;
	height: 21.625em;
	z-index: 4;
	&.scanning {
		&:after {
			opacity: 0;
		}
	}
	.searching {
		:deep() {
			.animation {
				top: calc(50% + 0.7em);
				left: calc(50% - 2em);
			}
		}
	}
	.border {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 82vw;
		height: 82vw;
		transform: translate(-50%, -50%);
		animation: frame 0.7s linear infinite alternate-reverse;
		z-index: 999;
		svg {
			width: 100%;
			height: 100%;
			&.success {
				path {
					animation: filled_border 0.5s infinite alternate-reverse;
				}
			}
		}
	}
}
.fixed_buttons {
	position: fixed;
	left: 50%;
	bottom: 2.4em;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateX(-50%);
	z-index: 4;
}
.fixed_button {
	position: relative;
	margin: 0 0.4em;
	width: 2.615em;
	height: 2.615em;
	border-radius: 50%;
	transition: all 300ms 500ms ease-in-out;
	z-index: 2;
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.5em;
		height: 1.5em;
		transform: translate(-50%, -50%);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
	}
	&.close_button {
		background-color: #ef3827;
		&:after {
			background-image: url("@/assets/images/svg/arrow.svg");
		}
	}
	&.history {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
		background-color: black;
		border-radius: 50px;
		padding: 0.6em 1.1em;
		width: auto;
		height: auto;
		z-index: 2;
		.icon {
			width: 1.813em;
			height: 1.813em;
			margin-right: 0.5em;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			background-image: url("@/assets/images/svg/history.svg");
		}
		.title {
			color: white;
			font-weight: 500;
			line-height: 1;
		}
		.count {
			display: block;
			width: 1.6em;
			padding-left: 0.5em;
			font-weight: 500;
			line-height: 1;
			color: $green;
		}
	}
	// &.search_button {
	// 	width: 3em;
	// 	height: 3em;
	// 	border: 1px solid white;
	// 	&:after {
	// 		background-image: url("@/assets/images/svg/loop.svg");
	// 	}
	// }
	// &.scanner_button {
	// 	border: 1px solid white;
	// 	&:after {
	// 		background-image: url("@/assets/images/svg/open_scanner.svg");
	// 	}
	// }
}

.search {
	position: absolute;
	top: 4.125em;
	left: 50%;
	width: calc(100% - 1.813em);
	height: 2.625em;
	background-color: white;
	border: 1px solid #e1e1e1;
	border-radius: 50px;
	transform: translateX(-50%);
	z-index: 4;
	transition: all 400ms ease-in-out;
	&.active {
		width: 100%;
		opacity: 1;
		.success {
			opacity: 1;
			transition: all 400ms 450ms ease-in-out;
		}
	}
	.lens {
		content: "";
		position: absolute;
		top: 50%;
		left: 0.8em;
		width: 1.375em;
		height: 1.375em;
		transform: translateY(-50%);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		background-image: url("@/assets/images/svg/loop.svg");
		filter: invert(1);
	}
	input {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 0.6em 3em 0.6em 2.813em;
		color: black;
		font-weight: 500;
		font-size: 0.913em;
		font-family: "Montserrat";
		z-index: 3;
		&::placeholder {
			color: #616060;
			font-weight: 500;
		}
	}
	.success {
		position: absolute;
		top: 50%;
		right: 0.3em;
		width: 2.05em;
		height: 2.05em;
		transform: translateY(-50%);
		opacity: 1;
		background-color: $green;
		border-radius: 50%;
		z-index: 3;
		transition: all 400ms 700ms ease-in-out;
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1em;
			height: 1em;
			transform: translate(-50%, -50%);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("@/assets/images/svg/check.svg");
			filter: brightness(0) invert(1);
		}
	}
	.tooltip {
		top: calc(100% + 1.1em);
		transform: translate(-50%, 0);
		padding: 0.65em 0.825em;
		&:after {
			top: 0;
			left: 50%;
			transform: translateY(-100%) rotate(180deg);
		}
	}

	// .success {
	// 	right: 0.25em;
	// 	width: 1em;
	// 	height: 1em;
	// 	padding: 1.5em;
	// 	transform: translateY(-50%);
	// 	opacity: 0;
	// 	z-index: 9;
	// 	background-repeat: no-repeat;
	// 	background-position: 50% 50%;
	// 	background-size: 35%;
	// 	background-image: url("@/assets/images/svg/check.svg");
	// 	transition: all 400ms ease-in-out;
	// 	// &:after,
	// 	// &:before {
	// 	// 	content: "";
	// 	// 	position: absolute;
	// 	// 	top: 50%;
	// 	// 	left: 50%;
	// 	// 	width: 100%;
	// 	// 	height: 2px;
	// 	// 	background-color: $red;
	// 	// }
	// 	// &:after {
	// 	// 	transform: translate(-50%, -50%) rotate(45deg);
	// 	// }
	// 	// &:before {
	// 	// 	transform: translate(-50%, -50%) rotate(-45deg);
	// 	// }
	// }
	// .success {
	// 	position: absolute;
	// 	top: 50%;
	// 	right: 0.25em;
	// 	width: 1em;
	// 	height: 1em;
	// 	padding: 1.5em;
	// 	transform: translateY(-50%);
	// 	opacity: 0;
	// 	z-index: 9;
	// 	background-repeat: no-repeat;
	// 	background-position: 50% 50%;
	// 	background-size: 35%;
	// 	background-image: url("@/assets/images/svg/check.svg");
	// 	transition: all 400ms ease-in-out;
	// 	// &:after,
	// 	// &:before {
	// 	// 	content: "";
	// 	// 	position: absolute;
	// 	// 	top: 50%;
	// 	// 	left: 50%;
	// 	// 	width: 100%;
	// 	// 	height: 2px;
	// 	// 	background-color: $red;
	// 	// }
	// 	// &:after {
	// 	// 	transform: translate(-50%, -50%) rotate(45deg);
	// 	// }
	// 	// &:before {
	// 	// 	transform: translate(-50%, -50%) rotate(-45deg);
	// 	// }
	// }
}

@keyframes frame {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}

	100% {
		transform: translate(-50%, -50%) scale(1.08);
	}
}

@keyframes filled_border {
	0% {
		fill: #00f900;
	}

	100% {
		fill: #fff;
	}
}
</style>
