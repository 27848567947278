<template>
	<div class="searching">
		<div class="animation">
			<lottie-animation
				ref="anim"
				:animationData="require('@/assets/lottie/searching2.json')"
				:loop="true"
				:autoPlay="true"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},

	methods: {
		showAnimation() {
			let searching = document.querySelector(".searching");
			searching.style.display = "block";
			setTimeout(() => {
				searching.classList.add("show");
				setTimeout(() => {
					this.playAnimation();
				}, 250);
			}, 50);
		},

		hideAnimation() {
			let searching = document.querySelector(".searching");
			searching.classList.remove("show");
			this.stopAnimation();
			setTimeout(() => {
				searching.style.display = "none";
			}, 400);
		},

		stopAnimation() {
			this.$refs.anim.stop();
		},

		playAnimation() {
			this.$refs.anim.play();
		}
	}
};
</script>

<style lang="scss" scoped>
.searching {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	opacity: 0;
	transition: $defaultTransition;
	&.show {
		opacity: 1;
	}
	.animation {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		:deep() {
			svg {
				width: 130% !important;
				height: 130% !important;
				filter: invert(1);
			}
		}
	}
}
</style>
