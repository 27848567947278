import { createApp } from "vue";
import App from "@/App";
import { router } from "@/router";
import "./registerServiceWorker";
//import "./registerServiceWorker";
import { store } from "@/store/index";
import LottieAnimation from "lottie-web-vue";
const app = createApp(App);
app.use(router).use(store).use(LottieAnimation).mount("#app");

store.subscribe((mutation, state) => {
	localStorage.setItem("searchHistory", JSON.stringify(state.searchHistory));
});
