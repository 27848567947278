<template>
	<custom-header />
	<router-view />
	<horizontal-lock />
</template>

<script>
import CustomHeader from "@/components/Common/CustomHeader.vue";
import HorizontalLock from "@/components/Modals/HorizontalLock.vue";
export default {
	components: { CustomHeader, HorizontalLock },

	beforeCreate() {
		this.$store.commit("initialiseSearchStorage");
	}
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
</style>
