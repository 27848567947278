let scrollY;
let event;
export function disableScroll(ios) {
	scrollY = window.scrollY;
	document.documentElement.classList.add("is-locked");
	let event = "pointermove";
	if (ios && parseInt(iOSversion()[0], 10) < 15) {
		event = "touchmove";
	}

	document.addEventListener(event, preventDefault);
	//modal.addEventListener("touchstart", preventDefault);
}

export function enableScroll() {
	document.documentElement.classList.remove("is-locked");
	document.removeEventListener(event, preventDefault);
	window.scrollTo(0, scrollY);
}

function preventDefault(e) {
	e.preventDefault();
	e.stopPropagation();
}

function iOSversion() {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
		return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	}
}
