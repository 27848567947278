<template>
	<transition-group name="show" tag="div" class="product_list">
		<product-item
			:key="product.link + index + selectedFilter"
			v-for="(product, index) in products"
			:product="product"
			@openResource="openResource($event)"
		/>
	</transition-group>
</template>

<script>
import ProductItem from "@/components/Products/ProductItem.vue";
export default {
	components: { ProductItem },

	props: {
		products: {
			type: Array,
			required: true
		},
		selectedFilter: {
			type: String,
			required: false
		}
	},

	methods: {
		openResource(resource) {
			this.$emit("openResource", resource);
		}
	}
};
</script>

<style lang="scss" scoped>
.product_list {
	display: flex;
	flex-wrap: wrap;
	// margin: 0 -0.5em;
}
</style>
